@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  @apply text-primary-500;
}
a:hover {
  @apply text-primary-700;
}

.pb-video {
  padding-bottom: 56.25%;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  @apply bg-accent-500;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px theme('colors.accent.500'),
    0 0 5px theme('colors.accent.500');
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 1.5rem;
  left: 100px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: theme('colors.primary.200');
  border-left-color: theme('colors.primary.200');
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Horizontal scrolling */
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.index-slider {
  @apply ml-6;
  @apply mr-4;
}

@media screen(md) {
  .index-slider-wrap {
    padding-left: 50%;
  }
  .index-slider {
    margin-left: calc(-768px / 2 + 1.5rem);
  }
}

@media screen(lg) {
  .index-slider {
    margin-left: 0;
    margin-right: 0;
  }
}

/* @media screen(lg) {
  .index-slider {
    margin-left: calc(-1024px / 2 + 1.5rem);
  }
}

@media screen(xl) {
  .index-slider {
    margin-left: calc(-1280px / 2 + 5.5rem);
  }
}

@media screen(2xl) {
  .index-slider {
    margin-left: calc(-1536px / 2 + 5.5rem);
  }
} */
